#navbar-container{
padding-left: 0;
}

#navbar-brand-link{
padding-left: 0;
}
  
.PreLoginNavbar__navbar-brand {
/* font-family: "Ubuntu"; */
font-size: 2.5rem;
font-weight: bold;
color: #fff;
}

.PreLoginNavbar__nav-item{
padding: 0 10px;
}

.PreLoginNavbar__nav-link {
font-size: 1.2rem;
/* font-family: "Montserrat-Light"; */
}

.navbar {
    padding-top: 15px;
    padding-bottom: 15px;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    font-size: 12px;
    letter-spacing: 5px;
}
.navbar-nav  li a:hover {
    color: #1abc9c !important;
}