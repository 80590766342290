.push {
  height: 500px;
}

.footer{
    background-color: #a1d7e3 !important;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.footer-block-title{
  font-weight: bold;
  color: #fff;

}

.footer-info{
  color: #fff !important;
  font-size: .8rem;
  margin: 0px!important;
}

.last-item{
  padding-bottom: 10px;
}

.link{
  color: white!important;
  font-size: .8rem;

}