.x-icon {
    /* display:none; */
    position: relative;
    float: right;
    margin-right: 10px;
    /* color: #f5ba13; */
    border: none;
    width: 10px;
    height: 10px;
    cursor: pointer;
    outline: none;
}

.watchlist-item{
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 2px 5px #ccc;
    padding: 10px;
    width: 240px;
    margin: 16px;
    /* float: left; */
    display: inline-block;
}

.watchlist-loading{
    /* float: left; */
    padding-top: 30px;
    position:absolute;
    display: inline-block;
}

.watchlist-service-image{
    width: 30px;
    height:30px;
    padding: 3px;
}
