

.login-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #a1d7e3;
}

.login-button-box{
  padding-bottom: 20px;
}

  
.login-screen__form {
  width: 380px;
  padding: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 10px;
  /* overflow: hidden; */
  text-align: center !important;
}

.login-screen__info-div{
  padding-top: 14%;
  text-align: center !important;
}

.form-box{
  padding-left: 13%;
}

@media (max-width: 500px){
  .login-screen__info-div{
    padding-top: 0%;
    padding-bottom: 5%;
  }

  .login-screen__form{
    width: 80%;
  }

  .login-info{
    font-size: 1rem;
  }

  .title-info{
    font-size: 4rem;
  }
}

.login-info{
  text-align: center!important;
  color: #fff;
  font-size: 1.5rem;
}

.title-info{
  color: #fff !important;
  font-size: 5rem !important;
  font-weight: bold;
}

.login-screen__title {
  padding-bottom: 5%;
  text-align: center;
  margin-bottom: 1rem;
  text-emphasis: bold;
}

.login-screen__forgotpassword {
  font-size: 0.7rem;
  text-align: center !important;
}

.login-screen__subtext {
  font-size: 0.7rem;
  display: block;
  margin: 0.5rem 0;
}
