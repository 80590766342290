#watchlist-screen__watchlist-input {
  width: 50%;
  padding-bottom:15px;
}

#watchlist-screen__button-addon1 {
  text-align: center !important;
}

.watchlist-screen__errorDisplay {
  color: red;
}


form.media-input input{
  width: 100%;
  border: none;
  padding: 4px;
  outline: none;
  font-size: 1.2em;
  font-family: inherit;
  resize: none;
}

form.media-input button {
  position: absolute;
  right: 18px;
  bottom: -18px;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: #ba9bc4;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  outline: none;
}

.second-col{
  padding-left: 20px;
  padding-top: 20px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
  border-radius: 7px;
  background-color: #fff;
}

.watchlist-title{
  color: black;
}

#input-area{
  position: relative;
  width: 480px;
  margin: 30px auto 20px auto;
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);
}

body{
  background-color: #f7f8fa !important;
}
  