
html {
  min-height: 100%;
  position: relative;
}
  
.container {
  padding-top: 15px;
}


p {
color: #8f8f8f;
}
  
body {
    text-align: center;
}
  
  
  /* Headings */

/* .test{
  opacity: .5;
} */
  
.loginRegister {
text-align: left;
}

.home-screen__big-heading {
/* font-family: "Montserrat-Black"; */
font-size: 3.5rem;
line-height: 1.5;
}
  
.section-heading {
font-size: 3rem;
line-height: 1.5;
}
  
  /* Containers */
  
.home-screen__container-fluid{
  padding: 7% 15%;
}
  
  /* Sections */
  
  
  
.home-screen__white-section {
background-color: #fff;
}
  
  /* Navigation Bar */
  
  
  /* Buttons */
  
.home-screen__download-button {
margin: 5% 3% 5% 0;
}
  
  /* Title Section */
  
  
  
.title .container-fluid {
  padding: 3% 15% 7%;
}
  
  /* Title Image */
  
.home-screen__title-image {
width: 30%;
/* transform: rotate(25deg); */
position: absolute;
right: 20%;
}
  
  /* Features Section */

#features {
position: relative;
}

.home-screen__feature-title {
color: Black;
font-size: 1.5rem;
text-align: center !important;

}

.home-screen__features-explain{
  text-align: center;
}

.home-screen__feature-box {
  text-align: center !important;
  padding: 3.5%;
}

.home-screen__features-icon {
  color: #c8a1e3;
  margin-bottom: 1rem;
}

.home-screen__features-icon:hover {
  color: #a1d7e3;
}


/* Pricing Section */

#pricing {
padding: 100px;
}

.price-text {
  font-size: 3rem;
  line-height: 1.5;
}

.pricing-column {
  padding: 3% 2%;
}
  
  /* CTA Section */
  
#cta{
  text-align: center;
}
  
  /* Footer Section */

#footer{
  padding: 2%;
  text-align: center ;
}
  
  
.social-icon {
margin: 20px 10px;
}
  
@media (max-width: 1100px) {

  .home-screen__top-buttons{
    text-align: center;
  }

  .home-screen__big-heading {
    text-align: center;
  }

  .home-screen__title-image {
    position: static;
    width: 30%;
  }

  .home-screen__img-div{
    text-align: center;
  }
}

@media (max-width: 900px) {
  .home-screen__big-heading{
      font-size: 2rem;
  }
}

.home-screen__colored-section-title{
  padding-top: 0 !important;
  padding-bottom: 175px;
}



.home-screen__colored-section {
background-color: #a1d7e3;
color: #fff;
}

.home-screen__title {
background-color: #a1d7e3;
color: #fff;
text-align: left;
}

.title .container-fluid{
padding-left: 15%;
}

.footer-text{
  text-align: center;
}

/*register page and login page */
.google-card{
width: 217px;
}

.google-card-body{
width: 232px;
}
