.resetpassword-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .resetpassword-screen__form {
    width: 380px;
    padding: 1.5rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    background: #fff;
  }
  
  .resetpassword-screen__title {
    text-align: center;
    margin-bottom: 1rem;
  }