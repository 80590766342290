.logo-img{
  width: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.logo-img-header{
  width: 30px;
  /* padding-right: 10px; */
}

.header-tag{
  font-weight: bold;
  color: black;
  padding-top: 15px;
  padding-left: 15px !important;
}

.price-tag{
  padding-top: 15px;
  color: black;
  position: absolute;
  right: 100px;
}

.checkbox-label{
  padding-left: 5px;
}

.preferences-loadingRing {
  padding-bottom:30px;
}