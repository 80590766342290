/* .navbar {
  padding: 0 0 4.5rem;
} */

 .brand-styling{
  color: #fff !important;
  font-size: 2.3rem !important;
  font-weight: bold;
  padding-left: 10px;
} 

/* .nav-item {
  padding: 0 10px;
}

*/

.nav-link {
  font-size: 1.2rem ;
  color: #fff !important;
} 

.navbar-toggle-button{
  color: #fff !important;
}

.watchlist-link{
  padding-left: 50px !important;
  padding-right: 0px;
}



.post-login-navbar-root{
  background-color: #a1d7e3;
  padding-bottom: 10px;
}

.log-out-link{
  color: #000 !important;
}

.username-dropdown{
  position: absolute;
  right: 40px;
}

@media only screen and (max-width: 990px) {
  .username-dropdown{
    position:static;
  }
}


/* .active-link{
  color: #1cbc9c !important;
}  */
